import React, { useState, useEffect } from 'react';
import Typography from '../../../components/atoms/Typography';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Link from '../../../components/atoms/Link';
import ButtonMUI from '../../../components/atoms/Button';
import Title from '../../../components/atoms/Title';
import IconWithText from '../../../components/molecules/IconWithText';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import MenuList from '@material-ui/core/MenuList';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import useStyles from './style';
import MenuItem from '@material-ui/core/MenuItem';
import PopupWithBackground from '../../../components/organism/PopupWithBackground';
import DctSvgIcon from '../../../components/atoms/Svg';
import clsx from 'clsx';
import { canUseDOM } from '../../../utils/canUseDOM';
import { getProcessedData } from './preProcessData';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import get from 'lodash/get';
import { COMPONENT_NAME } from './locators';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import { createDynamicLabels, insertAnalyticsInfo } from '../../../../utils/analytics';
import {
  getPageName,
  getComponentName,
} from '../../../utils/getPageComponentInfo';
import Helmet from 'react-helmet';
import { getCurrentPageUrl } from '../../../utils/getPageUrl';
import { pageContentType } from '../../../utils/getContentType';
import { EVENT_TYPE } from '../../../../constants';

const largeTextLanguages = [
  'ar',
  'ru',
]

const MegaNav = props => {
  const { fields, lng, appConfig } = props;
  const pageName = getPageName(props);
  const componentName = getComponentName(props);
  const isMobile = useMediaQuery('(max-width:1023px)');
  const largeDesktop = useMediaQuery('(max-width:1680px)');
  const xlargeDesktop = useMediaQuery('(min-width:1681px)');
  const contentType = pageContentType(props).toLowerCase();
  const classes = useStyles({ contentType });
  const [l2Index, setL2Index] = useState();
  const [isMegaNav, setIsMegaNav] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [l3Menu, setL3Menu] = useState(false);

  const isLargeText = largeTextLanguages.indexOf(lng) > -1;

  const backButtonText = getDictionaryText('backButton');

  let prefetchLinks = appConfig && appConfig.PRELOAD_3PARTY_LINKS;

  prefetchLinks = prefetchLinks && prefetchLinks.split(',');

  let preloadFlag =
    appConfig &&
    appConfig.PRELOAD_LINK_MEGAMENU_IMAGE &&
    appConfig.PRELOAD_LINK_MEGAMENU_IMAGE.toLowerCase();
  if (preloadFlag === 'true') {
    preloadFlag = true;
  } else if (preloadFlag === 'false') {
    preloadFlag = false;
  }

  let noRetina =
    appConfig &&
    appConfig.NO_RETINA_MEGANAV &&
    appConfig.NO_RETINA_MEGANAV.toLowerCase();
  if (noRetina === 'true') {
    noRetina = true;
  } else if (noRetina === 'false') {
    noRetina = false;
  }

  const l1List = getProcessedData(fields && fields.megaNavList);

  const activeNavData = getProcessedData(fields && fields.megaNavList)[
    clickedIndex
  ];

  const navItemBgColor = get(
    activeNavData,
    'fields.values.backgroundColor.value',
    ''
  );
  const navItemBgImage = get(activeNavData, 'fields.values.imageUrl', '');

  const [bgColor, setBgColor] = useState(navItemBgColor);
  const [bgImage, setBgImage] = useState(navItemBgImage);
  const [bgMapImage, setBgMapImage] = useState();
  const [imageIndex, setImageIndex] = useState(-1);
  const [listLevel, setListLevel] = useState(false);

  const segmentTitle = get(activeNavData, 'fields.values.navSubTitle', '');
  const menuTitle = get(activeNavData, 'fields.values.navTitle', '');
  const menuTitleUrl = get(activeNavData, 'fields.values.itemUrl', '');
  const l2List = get(activeNavData, 'fields.secondaryNavigation', '');

  const l3List = get(
    activeNavData,
    `fields.secondaryNavigation[${l2Index}].subNavigation`,
    ''
  );

  const l3ListAnimationEnable = get(
    activeNavData,
    `fields.secondaryNavigation[${l2Index}].enableBackgroundImage`,
    ''
  );

  const l3ListTitle = get(
    activeNavData,
    `fields.secondaryNavigation[${l2Index}].values.navTitle`,
    ''
  );
  const l3ListUrl = get(
    activeNavData,
    `fields.secondaryNavigation[${l2Index}].values.itemUrl`,
    ''
  );

  const l2ImageList =
    l2List && l2List.map(listItem => listItem.values.imageUrl);

  const l3ImageList =
    l3List && l3List.map(listItem => listItem.values.imageUrl);

  const closeIconAriaLabel = getDictionaryText('closeIconAriaLabel');
  const l3ItemBgColor = get(
    activeNavData,
    `fields.secondaryNavigation[${l2Index}].values.backgroundColor.value`,
    ''
  );
  const l3ItemBgImage = get(
    activeNavData,
    `fields.secondaryNavigation[${l2Index}].values.imageUrl`,
    ''
  );

  useEffect(() => {
    try {
      if (canUseDOM) {
        const header = document.querySelector('header');
        header && contentType && contentType === EVENT_TYPE && header.classList.add('AdCalendarHeader');
        const selectedItem =
          header && header.querySelector('.nav-text.selected');
        const mobileMenuDrawer = document.querySelector('.mobileMenuDrawer');
        const menuItem = header && header.querySelectorAll('.nav-text');
        const backButton = document.querySelectorAll('.backButton');
        backButton &&
          backButton.forEach(btn => {
            btn.addEventListener('click', () => {
              btn.parentElement.parentElement.classList.remove('onScreen');
              mobileMenuDrawer &&
                mobileMenuDrawer.classList.remove('disableScroll');
            });
          });
        menuItem &&
          menuItem.forEach(item =>
            item.addEventListener('click', () => {
              selectedItem && selectedItem.classList.remove('selected');
              item.classList.add('selected');
              header && header.classList.add('megaNavOpened');
            })
          );

        menuItem.forEach(item => {
          item.addEventListener('mouseenter', () => {
            menuItem.forEach(otherItem => otherItem.classList.remove('hovered'));
            item.classList.add('hovered');

            menuItem.forEach(otherItem => {
              if (otherItem !== item) {
                otherItem.classList.add('unhovered');
              }
            });
          });

          item.addEventListener('mouseleave', () => {
            menuItem.forEach(otherItem => otherItem.classList.remove('hovered', 'unhovered'));
          });
        });
        if (!isMegaNav) {
          menuItem &&
            menuItem.forEach(item => item.classList.remove('selected'));
          header && header.classList.remove('megaNavOpened');
        }
        function addPreloadImages() {
          const preloadImagesDiv = document.createElement('div');
          preloadImagesDiv.classList.add('preloadImages');
          preloadImagesDiv.style.backgroundImage = preloadImageArray.map(item => `url(${item})`).join(', ');
          document.querySelector('#preloadImagesContainer').appendChild(preloadImagesDiv);
        }

        window.addEventListener('load', addPreloadImages);
      }
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    try {
      if (canUseDOM) {
        const searchIcon = document.querySelector('.search-icon');
        searchIcon &&
          searchIcon.addEventListener('click', () => {
            setIsMegaNav(false);
          });
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMegaNav]);

  const closeMegaNav = () => {
    setIsMegaNav(false);
  };

  const handleMegaNav = (index, itemBgColor, itemBgImage) => event => {
    event.preventDefault();
    setIsMegaNav(true);
    setBgColor(itemBgColor);
    setBgImage(itemBgImage);
    setClickedIndex(index);
    setListLevel(false);
    if (canUseDOM) {
      const menuWrapper = document.querySelector('.megaMenuWrapper');
      menuWrapper && menuWrapper.classList.remove('hideL2List');
      if (menuWrapper && menuWrapper.querySelector('.l2List')) {
        menuWrapper.querySelector('.l2List').style.display = 'block';
      }
    }
  };

  const handleMobileMenu = index => event => {
    event.preventDefault();
    setClickedIndex(index);
    const mobileMenuDrawer = document.querySelector('.mobileMenuDrawer');
    mobileMenuDrawer && mobileMenuDrawer.classList.add('disableScroll');
    const element = event.target.nodeName.toLowerCase();
    element === 'span'
      ? event.target.parentElement.nextSibling.classList.add('onScreen')
      : event.target.nextSibling.classList.add('onScreen');
  };

  const clickEvent = (event, index, subNavlength = false, navTitle = '') => {
    if (!subNavlength) {
      insertAnalyticsInfo({
        ...analyticsData.headerInteraction.navLinkClick,
        call_to_action: navTitle,
        nav_category: menuTitle
      })
      return
    }
    event.preventDefault();
    setL2Index(index);
    setListLevel(true);
    if (canUseDOM) {
      const menuWrapper = document.querySelector('.megaMenuWrapper');
      const backButton = document.querySelector(
        '.backButtonDesktop > .MuiButton-root'
      );
      menuWrapper && menuWrapper.classList.add('hideL2List');
      setTimeout(() => {
        backButton && backButton.setAttribute('tabindex', '0');
        if (menuWrapper && menuWrapper.querySelector('.l3List')) {
          menuWrapper.querySelector('.l3List').style.display = 'block';
          menuWrapper &&
            menuWrapper
              .querySelectorAll('.l3List a')
              .forEach(item => item.removeAttribute('tabindex'));
        }
        if (menuWrapper && menuWrapper.querySelector('.l2List')) {
          menuWrapper.querySelector('.l2List').style.display = 'none';
        }
      }, 300);
    }
  };

  const mobileClickEvent = index => event => {
    event.preventDefault();
    setL2Index(index);
    setL3Menu(true);
  };

  const hoverEvent = e => {
    const element = e.target.nodeName.toLowerCase();
    if (element === 'a') {
      const bgColor = e.target.getAttribute('data-bgColor');
      const x = e.target.getAttribute('data-x');
      const mapSrc = e.target.getAttribute('data-mapSrc');
      const xMaplarge = e.target.getAttribute('data-xLargeMapSrc');
      const mapLarge2x = e.target.getAttribute('data-large2xMapSrc');
      const mapLarge = e.target.getAttribute('data-largeMapSrc');
      const bgMapSrc = {
        value: {
          src: mapSrc,
          '3x2': {
            large: mapLarge,
            large2x: mapLarge2x,
            xlarge: xMaplarge,
          },
        },
      };
      setBgColor(bgColor);
      setImageIndex(x);
      if (mapSrc && mapSrc !== null) {
        setBgMapImage(bgMapSrc);
      }
    }
  };

  const leaveEvent = () => {
    if (canUseDOM) {
      const menuWrapper = document.querySelector('.megaMenuWrapper');
      if (menuWrapper && menuWrapper.classList.contains('hideL2List')) {
        setBgColor(l3ItemBgColor);
        setBgImage(l3ItemBgImage);
        setImageIndex(-1);
        setBgMapImage('');
      } else {
        setBgColor(navItemBgColor);
        setBgImage(navItemBgImage);
        setImageIndex(-1);
        setBgMapImage('');
      }
    }
  };

  const backToMenu = () => {
    if (canUseDOM) {
      const menuWrapper = document.querySelector('.megaMenuWrapper');
      const backButton = document.querySelector(
        '.backButtonDesktop > .MuiButton-root'
      );
      menuWrapper && menuWrapper.classList.remove('hideL2List');
      setTimeout(() => {
        backButton && backButton.setAttribute('tabindex', '-1');
        if (menuWrapper && menuWrapper.querySelector('.l3List')) {
          menuWrapper.querySelector('.l3List').style.display = 'none';
          menuWrapper &&
            menuWrapper
              .querySelectorAll('.l3List a')
              .forEach(item => item.setAttribute('tabindex', '-1'));
        }
        if (menuWrapper && menuWrapper.querySelector('.l2List')) {
          menuWrapper.querySelector('.l2List').style.display = 'block';
          menuWrapper.querySelector('.l2List li:first-child  > a').focus();
        }
      }, 300);
      setBgColor(navItemBgColor);
      setListLevel(false);
      setBgImage(navItemBgImage);
      setBgMapImage('');
    }
  };

  const backToMenuKeyDown = event => {
    if (canUseDOM) {
      const code = event && (event.keyCode || event.which);
      if (code === 13) {
        const menuWrapper = document.querySelector('.megaMenuWrapper');
        const backButton = document.querySelector(
          '.backButtonDesktop > .MuiButton-root'
        );
        menuWrapper && menuWrapper.classList.remove('hideL2List');
        setTimeout(() => {
          backButton && backButton.setAttribute('tabindex', '-1');
          if (menuWrapper && menuWrapper.querySelector('.l3List')) {
            menuWrapper.querySelector('.l3List').style.display = 'none';
            menuWrapper &&
              menuWrapper
                .querySelectorAll('.l3List a')
                .forEach(item => item.setAttribute('tabindex', '-1'));
          }
          if (menuWrapper && menuWrapper.querySelector('.l2List')) {
            menuWrapper.querySelector('.l2List').style.display = 'block';
            menuWrapper.querySelector('.l2List li:first-child  > a').focus();
          }
        }, 300);
        setBgColor(navItemBgColor);
        setListLevel(false);
        setBgImage(navItemBgImage);
        setBgMapImage('');
      }
    }
  };

  const backMobileMenu = () => {
    setL3Menu(false);
    const mobileMenuDrawer = document.querySelector('.mobileMenuDrawer');
    mobileMenuDrawer && mobileMenuDrawer.classList.add('disableScroll');
  };

  const renderMobileView = () => {
    return (
      <React.Fragment>
        <div
          className={clsx(classes.flyout, 'flyout')}
          data-locator={COMPONENT_NAME}
        >
          <div className="stickyPart">
            <div className="backButton">
              <ButtonMUI
                component="span"
                buttonType="secondary"
                hasBorder={false}
                isStartIcon={true}
              >
                {backButtonText}
              </ButtonMUI>
            </div>
            <Link
              data-id={get(analyticsData, 'dataIds.headerNavLinkClick', '')}
              data-link={createDynamicLabels([
                pageName,
                componentName,
                menuTitle && menuTitle.value,
              ])}
              link={menuTitleUrl}
            >
              <IconWithText
                isTextThenIcon={true}
                iconType="ArrowForwardSharpIcon"
                className={classes.menuTitle}
              >
                {menuTitle && (
                  <Typography
                    variant="h2"
                    component="span"
                    animateSettings={{
                      willAnimate: false,
                    }}
                  >
                    <Text field={menuTitle} />
                  </Typography>
                )}
              </IconWithText>
            </Link>
          </div>
          <MenuList className={classes.mobileMegaMenu}>
            {l2List &&
              l2List.map((item, i) => {
                const navTitle = get(item, 'values.navTitle', '');
                const subNavlength = get(item, 'subNavigation', '').length > 0;
                const itemUrl = get(item, 'values.itemUrl', '');
                return (
                  navTitle && (
                    <MenuItem key={i}>
                      <Link
                        link={itemUrl}
                        data-id={get(
                          analyticsData,
                          'dataIds.headerNavLinkClick',
                          ''
                        )}
                        data-link={createDynamicLabels([
                          pageName,
                          componentName,
                          navTitle.value,
                        ])}
                        onClick={subNavlength ? mobileClickEvent(i) : undefined}
                      >
                        <Typography
                          variant="h5"
                          component="span"
                          animateSettings={{
                            willAnimate: false,
                          }}
                        >
                          <Text field={navTitle} />
                        </Typography>
                        {subNavlength && (
                          <DctSvgIcon name="ArrowForwardIosSharp"></DctSvgIcon>
                        )}
                      </Link>
                    </MenuItem>
                  )
                );
              })}
          </MenuList>
        </div>
        <div className={clsx(classes.flyout, l3Menu && 'onScreen')}>
          <div className="stickyPart">
            <div className="backButton" onClick={backMobileMenu}>
              <ButtonMUI
                component="span"
                buttonType="secondary"
                hasBorder={false}
                isStartIcon={true}
              >
                {backButtonText}
              </ButtonMUI>
            </div>
            <Link
              link={l3ListUrl}
              data-id={get(analyticsData, 'dataIds.headerNavLinkClick')}
              data-link={createDynamicLabels([
                pageName,
                componentName,
                l3ListTitle && l3ListTitle.value,
              ])}
            >
              <IconWithText
                isTextThenIcon={true}
                iconType="ArrowForwardSharpIcon"
                className={classes.menuTitle}
              >
                <Typography
                  variant="h2"
                  component="span"
                  animateSettings={{
                    willAnimate: false,
                  }}
                >
                  <Text field={l3ListTitle} />
                </Typography>
              </IconWithText>
            </Link>
          </div>
          <MenuList className={classes.mobileMegaMenu}>
            {l3List &&
              l3List.map((item, i) => {
                const navTitle = get(item, 'values.navTitle', '');
                const itemUrl = get(item, 'values.itemUrl', '');
                return (
                  navTitle && (
                    <MenuItem key={i}>
                      <Link
                        link={itemUrl}
                        data-id={get(
                          analyticsData,
                          'dataIds.headerNavLinkClick'
                        )}
                        data-link={createDynamicLabels([
                          pageName,
                          componentName,
                          navTitle && navTitle.value,
                        ])}
                      >
                        <Typography
                          variant="h5"
                          component="span"
                          animateSettings={{
                            willAnimate: false,
                          }}
                        >
                          <Text field={navTitle} />
                        </Typography>
                      </Link>
                    </MenuItem>
                  )
                );
              })}
          </MenuList>
        </div>
      </React.Fragment>
    );
  };

  const renderDesktopView = () => {
    return (
      activeNavData !== undefined && (
        <PopupWithBackground
          shouldOpen={isMegaNav}
          onCloseClick={closeMegaNav}
          backgroundImage={bgImage || navItemBgImage}
          backgroundColor={bgColor || navItemBgColor}
          listImageArray={listLevel ? l3ImageList : l2ImageList}
          imageIndex={imageIndex}
          mapImage={bgMapImage}
          disableLazyLoad={true}
          noRetina={noRetina}
          anchor={'top'}
          closeIconAriaLabel={closeIconAriaLabel}
        >
          <div
            className={clsx(classes.desktopMegaMenuWrapper, 'megaMenuWrapper')}
            data-locator={COMPONENT_NAME}
          >
            <div className={clsx(classes.desktopMegaMenu, 'l2List')}>
              <div className="stickyPart">
                {segmentTitle && <Title text={segmentTitle} />}
                {menuTitle && (
                  <Link
                    link={menuTitleUrl}
                    data-id={get(analyticsData, 'dataIds.headerNavLinkClick')}
                    data-link={createDynamicLabels([
                      pageName,
                      componentName,
                      menuTitle && menuTitle.value,
                    ])}
                  >
                    <IconWithText
                      isTextThenIcon={true}
                      iconType="ArrowForwardSharpIcon"
                      className={classes.menuTitle}
                      isAnimate={true}
                    >
                      <Typography
                        variant="h4"
                        component="h2"
                        title={menuTitle.value}
                        animateSettings={{
                          willAnimate: false,
                        }}
                      >
                        <Text field={menuTitle} />
                      </Typography>
                    </IconWithText>
                  </Link>
                )}
              </div>
              <MenuList>
                {l2List &&
                  l2List.map((item, i) => {
                    const itemBgColor = get(
                      item,
                      'values.backgroundColor.value',
                      ''
                    );
                    const itemUrl = get(item, 'values.itemUrl', '');
                    const navTitle = get(item, 'values.navTitle.value', '');
                    const subNavlength =
                      item &&
                      item.subNavigation &&
                      item.subNavigation.length > 0;
                    return (
                      <MenuItem key={i} tabIndex="-1">
                        <Link
                          data-id={get(
                            analyticsData,
                            'dataIds.headerNavLinkClick',
                            ''
                          )}
                          data-link={createDynamicLabels([
                            pageName,
                            componentName,
                            get(item, 'values.navTitle.value', ''),
                          ])}
                          link={itemUrl}
                          onClick={(event) => clickEvent(event, i, subNavlength, navTitle)}
                          onMouseOver={hoverEvent}
                          onMouseOut={leaveEvent}
                          data-x={i}
                          data-bgColor={itemBgColor}
                        >
                          <Typography variant="body1" component="span">
                            <Text field={get(item, 'values.navTitle', '')} />
                          </Typography>
                          {subNavlength && (
                            <DctSvgIcon name="ArrowForwardIosSharp"></DctSvgIcon>
                          )}
                        </Link>
                      </MenuItem>
                    );
                  })}
              </MenuList>
            </div>
            <div className={clsx(classes.desktopMegaMenu, 'l3List')}>
              <div className="stickyPart">
                <div
                  className="backButtonDesktop"
                  onClick={backToMenu}
                  onKeyDown={e => backToMenuKeyDown(e)}
                >
                  <ButtonMUI
                    component="span"
                    buttonType="secondary"
                    hasBorder={false}
                    isStartIcon={true}
                    tabIndex="-1"
                  >
                    {backButtonText}
                  </ButtonMUI>
                </div>
                <Link
                  link={l3ListUrl}
                  data-id={get(analyticsData, 'dataIds.headerNavLinkClick', '')}
                  data-link={createDynamicLabels([
                    pageName,
                    componentName,
                    l3ListTitle && l3ListTitle.value,
                  ])}
                >
                  <IconWithText
                    isTextThenIcon={true}
                    iconType="ArrowForwardSharpIcon"
                    className={classes.menuTitle}
                    isAnimate={true}
                  >
                    <Typography
                      variant="h4"
                      component="h2"
                      animateSettings={{
                        willAnimate: false,
                      }}
                    >
                      <Text field={l3ListTitle} />
                    </Typography>
                  </IconWithText>
                </Link>
              </div>
              <MenuList>
                {l3List &&
                  l3List.map((item, i) => {
                    const itemBgColor = get(
                      item,
                      'values.backgroundColor.value',
                      ''
                    );
                    const itemUrl = get(item, 'values.itemUrl', '');

                    return (
                      <MenuItem key={i} tabIndex="-1">
                        <Link
                          data-id={get(
                            analyticsData,
                            'dataIds.headerNavLinkClick',
                            ''
                          )}
                          data-link={createDynamicLabels([
                            pageName,
                            componentName,
                            get(item, 'values.navTitle.value', ''),
                          ])}
                          link={itemUrl}
                          onClick={() => {
                            insertAnalyticsInfo({
                              ...analyticsData.headerInteraction.navLinkClick,
                              call_to_action: get(item, 'values.navTitle.value', ''),
                              nav_category: menuTitle.value
                            })
                          }}
                          data-x={i}
                          onMouseOver={l3ListAnimationEnable && hoverEvent}
                          onMouseOut={l3ListAnimationEnable && leaveEvent}
                          data-bgColor={l3ListAnimationEnable && itemBgColor}
                          data-mapSrc={get(
                            item,
                            'values.mapImageUrl.value.src',
                            ''
                          )}
                          data-xLargeMapSrc={get(
                            item,
                            'values.mapImageUrl.value.3x2.xlarge',
                            ''
                          )}
                          data-large2xMapSrc={get(
                            item,
                            'values.mapImageUrl.value.3x2.large2x',
                            ''
                          )}
                          data-largeMapSrc={get(
                            item,
                            'values.mapImageUrl.value.3x2.large',
                            ''
                          )}
                        >
                          <Typography variant="body1" component="span">
                            <Text field={get(item, 'values.navTitle', '')} />
                          </Typography>
                        </Link>
                      </MenuItem>
                    );
                  })}
              </MenuList>
            </div>
          </div>
        </PopupWithBackground>
      )
    );
  };

  let preloadImageArray = [];

  if (!isMobile) {
    if (xlargeDesktop) {
      l1List &&
        l1List.length > 0 &&
        l1List.forEach(l1 => {
          preloadImageArray.push(
            get(l1, 'fields.values.imageUrl.value[3x2].xLarge', '')
          );
          get(l1, 'fields.secondaryNavigation', []).forEach(l2 => {
            if (l2.enableBackgroundImage) {
              preloadImageArray.push(
                get(l2, 'values.imageUrl.value[3x2].xLarge', '')
              );
              get(l2, 'subNavigation', []).forEach(l3 => {
                preloadImageArray.push(
                  get(l3, 'values.imageUrl.value[3x2].large', '')
                );
              });
            }
          });
        });
      preloadImageArray = preloadImageArray.filter(item => item);
    } else if (largeDesktop) {
      l1List &&
        l1List.length > 0 &&
        l1List.forEach(l1 => {
          preloadImageArray.push(
            get(l1, 'fields.values.imageUrl.value[3x2].large', '')
          );
          get(l1, 'fields.secondaryNavigation', []).forEach(l2 => {
            if (l2.enableBackgroundImage) {
              preloadImageArray.push(
                get(l2, 'values.imageUrl.value[3x2].large', '')
              );
              get(l2, 'subNavigation', []).forEach(l3 => {
                preloadImageArray.push(
                  get(l3, 'values.imageUrl.value[3x2].large', '')
                );
              });
            }
          });
        });
      preloadImageArray = preloadImageArray.filter(item => item);
    }
  }

  let isLinkActive;
  let pathName = getCurrentPageUrl(props);
  pathName =
    pathName &&
    pathName.split('/') &&
    pathName.split('/').length > 1 &&
    pathName.split('/')[1];

  return (
    <React.Fragment>
      <Helmet>
        {prefetchLinks &&
          prefetchLinks.length > 0 &&
          prefetchLinks.map((item, i) => {
            return <link key={i} rel="preconnect" href={item} crossOrigin />;
          })}
      </Helmet>
      <div id="preloadImagesContainer"></div>
      <List
        component="ul"
        classes={{
          root: `${classes.root} ${classes.navigationList} mega-navigation-list`,
        }}
        role="list"
        aria-label="main navigation"
      >
        {l1List &&
          l1List.length > 0 &&
          l1List.map((item, index) => {
            const { navTitle, itemUrl } = get(item, 'fields.values', '');
            let itemUrlPathName =
              get(itemUrl, 'value.href', '') &&
              get(itemUrl, 'value.href', '').split('/') &&
              get(itemUrl, 'value.href', '').split('/').length > 0 &&
              get(itemUrl, 'value.href', '').split('/')[2];
            if (itemUrlPathName === pathName) isLinkActive = 'active';
            else isLinkActive = '';
            const secondaryNav = get(item, 'fields.secondaryNavigation', '');
            const hasL2Pages = secondaryNav && secondaryNav.length > 0;
            const itemBgColor = get(item, 'values.backgroundColor.value', '');
            const itemBgImage = get(item, 'values.imageUrl', '');
            return (
              navTitle && (
                <ListItem
                  key={index}
                  dense={false}
                  disableGutters={true}
                  data-locator="header-component-navList-item"
                  onClick={() => {
                    if (hasL2Pages) return
                    insertAnalyticsInfo({
                      ...analyticsData.headerInteraction.navLinkClick,
                      call_to_action: navTitle.value,
                      nav_category: navTitle.value
                    })
                  }}
                  className={clsx(
                    classes.navListItem,
                    {
                      [classes.largeTextNavListItem]: isLargeText,
                    }
                  )}
                  alignItems="flex-start"
                >
                  <Link
                    tabIndex="0"
                    data-id={get(
                      analyticsData,
                      'dataIds.headerNavLinkClick',
                      ''
                    )}
                    data-link={createDynamicLabels([
                      pageName,
                      componentName,
                      navTitle.value,
                    ])}
                    srText={navTitle.value}
                    link={itemUrl}
                    className={`nav-text ${isLinkActive}`}
                    onClick={
                      hasL2Pages ?
                        (!isMobile
                          ? handleMegaNav(index, itemBgColor, itemBgImage)
                          : handleMobileMenu(index))
                        : undefined
                    }
                  >
                    <Typography
                      variant={!isMobile ? 'body2Bold' : 'h5'}
                      className={clsx({
                        [classes.navigationTitle]: !isMobile && isLargeText,
                      })}
                      component="span"
                      animateSettings={{
                        willAnimate: false,
                      }}
                    >
                      <Text field={navTitle} />
                    </Typography>
                    {isMobile && hasL2Pages && (
                      <DctSvgIcon name="ArrowForwardIosSharp"></DctSvgIcon>
                    )}
                  </Link>

                  {isMobile && renderMobileView()}
                </ListItem>
              )
            );
          })}
      </List>
      {!isMobile && renderDesktopView()}
    </React.Fragment>
  );
};

export default withSitecoreContext()(withNamespaces()(MegaNav));
