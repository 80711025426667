import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { EVENT_TYPE } from '../../../../constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  navListItem: {
    backgroundClip: 'padding-box',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      borderBottom: `1px solid ${theme.mixins.Black(120)}`,
    },
    // Decrease size to make room for additional menu items.
    '@media (min-width: 1024px)': {
      '&:last-child': {
        ...theme.mixins.marginRight('0 !important'),
      },
    },
    '@media (min-width: 1024px) and (max-width: 1750px)': {
      ...theme.mixins.marginRight('3% !important'),
    },
    '@media (min-width: 1024px) and (max-width: 1690px)': {
      ...theme.mixins.marginRight('2.5% !important'),
    },
    '@media (min-width: 1024px) and (max-width: 1560px)': {
      ...theme.mixins.marginRight('2% !important'),
    },
    '@media (min-width: 1024px) and (max-width: 1140px)': {
      ...theme.mixins.marginRight('2% !important'),
    },
    '@media (min-width: 1024px) and (max-width: 1530px)': {
      '& .MuiTypography-subTitleBold': {
        fontSize: `${fontSize.s16Rem}  !important`,
      },
    },
    '@media (min-width: 1024px) and (max-width: 1800px)': {
      '& .MuiTypography-subTitleBold': {
        '[lang="ar"] &, [lang="he"] &': {
          fontSize: `${fontSize.s12point8Rem}  !important`,
        },
      },
    },
    '@media (min-width: 1024px) and (max-width: 1700px)': {
      '& .MuiTypography-subTitleBold': {
        '[lang="ar"] &, [lang="he"] &': {
          fontSize: `${fontSize.s11point2Rem}  !important`,
        },
      },
    },
    '@media (min-width: 1024px) and (max-width: 1160px)': {
      '& .MuiTypography-subTitleBold': {
        '[lang="ar"] &, [lang="he"] &': {
          fontSize: `${fontSize.s10point4Rem}  !important`,
        },
      },
    },
  },
  navigationList: {
    listStyle: 'none',
    padding: '0',
    '@media (min-width: 1024px)': {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      ...theme.mixins.paddingLeft(pxToRem(20)),
      ...theme.mixins.paddingRight(pxToRem(20)),
    },
    '& .MuiListItem-dense': {
      padding: 0,
    },
    '& .MuiListItem-root': {
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        width: 'auto',
        ...theme.mixins.marginRight('3.5%'),
        minHeight: pxToRem(75),
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
          ...theme.mixins.marginRight(pxToRem(16)),
        },
      },
    },
    '& .nav-text': {
      '.is-transparent &': {
        '&.hovered': {
          opacity: 1,
          transition: 'opacity 0.3s ease-in-out 100ms',

        },
        '&.unhovered': {
          opacity: 0.5,
          transition: 'opacity 0.3s ease-in-out 100ms',

        },
      },
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      width: '100%',
      color: 'inherit',
      display: 'block',
      textAlign: theme.mixins.textLeft(),
      [theme.breakpoints.up('lg')]: {
        paddingTop: '0',
        paddingBottom: '0',
        width: 'auto',
      },
      '&:hover::after': {
        [theme.breakpoints.up('lg')]: {
          transform: 'scaleX(1)',
        },
      },
      '&::after': {
        [theme.breakpoints.up('lg')]: {
          display: 'block',
          content: '""',
          borderBottom: `4px solid ${theme.mixins.AbuDhabiOrange()}`,
          position: 'absolute',
          top: pxToRem(98),
          width: '100%',
          transform: 'scaleX(0)',
          transition: 'transform 0.3s ease-in-out',
        },
      },
      '& .MuiSvgIcon-root': {
        fontSize: fontSize.s16Rem,
        position: 'absolute',
        ...theme.mixins.right(0),
        top: `calc(50% - ${pxToRem(8)})`,
        pointerEvents: 'none',
        transform: theme.mixins.rotate0(),
      },
      '&.selected': {
        color: theme.mixins.AbuDhabiOrange(),
        '&:hover::after': {
          [theme.breakpoints.up('lg')]: {
            transform: 'scaleX(1)',
          },
        },
        '&::after': {
          [theme.breakpoints.up('lg')]: {
            top: pxToRem(98),
            transform: 'scaleX(1)',
            borderBottom: `4px solid ${theme.mixins.AbuDhabiOrange()}`,
          },
        },
      },
      '&:hover': {
        [theme.breakpoints.up('lg')]: {
          color: theme.mixins.AbuDhabiOrange(),
        },
      },
      '&.active': {
        // This comments for Event page
        color: ({ contentType }) =>
          contentType === EVENT_TYPE
            ? theme.mixins.AbuDhabiOrange()
            : `${theme.mixins.AbuDhabiOrange()}`,
        // color: theme.mixins.AbuDhabiOrange(),
        '&::after': {
          [theme.breakpoints.up('lg')]: {
            borderBottom: '4px solid',
          },
        },
      },
    },
    '& .MuiListItem-divider': {
      [theme.breakpoints.up('lg')]: {
        borderBottom: 'none',
      },
    },
  },
  largeTextNavListItem: {
    '&.MuiListItem-root': {
      '@media (min-width: 1640px) and (max-width: 2024px)': {
        ...theme.mixins.marginRight(pxToRem(36)),
      },
      '@media (min-width: 1606px) and (max-width: 1639px)': {
        ...theme.mixins.marginRight(pxToRem(30)),
      },
      '@media (min-width: 1480px) and (max-width: 1605px)': {
        ...theme.mixins.marginRight(pxToRem(28)),
      },
      '@media (min-width: 1460px) and (max-width: 1479px)': {
        ...theme.mixins.marginRight(pxToRem(26)),
      },
      '@media (min-width: 1440px) and (max-width: 1459px)': {
        ...theme.mixins.marginRight(pxToRem(22)),
      },
      '@media (min-width: 1000px) and (max-width: 1439px)': {
        ...theme.mixins.marginRight(pxToRem(20)),
      },
    },
  },
  navigationTitle: {
    '@media (min-width: 1300px) and (max-width: 1560px)': {
      fontSize: fontSize.s15px,
    },
  },
  flyout: {
    height: `calc(100% - ${pxToRem(81)})`,
    left: '-100%',
    position: 'fixed',
    top: pxToRem(81),
    transition: 'all .3s ease',
    visibility: 'hidden',
    width: '100%',
    zIndex: '1002',
    overflowY: 'auto',
    backgroundColor: theme.mixins.White(),
    padding: `0 ${theme.spacing(3)}`,
    '& .stickyPart': {
      position: 'sticky',
      paddingTop: theme.spacing(4),
      top: '0',
      backgroundColor: theme.mixins.White(),
      zIndex: '11',
      '& a': {
        display: 'block',
      },
    },

    '& .backButton': {
      marginBottom: theme.spacing(3),
      textAlign: theme.mixins.textLeft(),
      '& .MuiButton-textSecondary:hover': {
        backgroundColor: 'transparent',
        color: 'inherit',
      },
      '& .MuiButton-label': {
        color: theme.mixins.Black(),
      },
      '& .MuiButton-startIcon': {
        transform: 'rotate(180deg)',
        padding: '0',
        width: 'auto',
      },
      '& > span:hover': {
        '& .MuiButton-label': {
          color: 'inherit',
        },
        '& .MuiButton-startIcon': {
          color: 'inherit',
          backgroundColor: 'transparent',
        },
      },
    },
  },
  menuTitle: {
    marginTop: pxToRem(24),
    marginBottom: pxToRem(47),
    alignItems: 'baseline',
    '& .MuiTypography-root': {
      ...theme.mixins.marginLeft(0),
      textAlign: theme.mixins.textLeft(),
      wordBreak: 'break-word',
      ...theme.palette.common.ellipsis(2),
    },
    '& .MuiAvatar-colorDefault': {
      transition: 'background-color 0.3s ease-in-out',
      backgroundColor: theme.mixins.AbuDhabiOrange(),
    },
    '& .MuiAvatar-root .animateIcon': {
      height: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
      '&:hover': {
        '& .MuiTypography-root': {
          textDecoration: 'underline',
        },
        '& .MuiAvatar-colorDefault': {
          backgroundColor: theme.mixins.Black(),
        },
        '& .MuiAvatar-root .animateIcon': {
          animationName: '$arrowMove',
          animationDuration: '.5s',
          animationIterationCount: '1',
        },
      },
    },
  },
  mobileMegaMenu: {
    padding: 0,
    '& > li': {
      padding: 0,
      minHeight: 0,
      whiteSpace: 'normal',
      '& > a': {
        display: 'block',
        width: '100%',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        textAlign: theme.mixins.textLeft(),
        '& .MuiSvgIcon-root': {
          fontSize: fontSize.s16Rem,
          transform: theme.mixins.rotate0(),
          position: 'absolute',
          ...theme.mixins.right(0),
          top: `calc(50% - ${pxToRem(8)})`,
        },
      },
    },
  },
  desktopMegaMenuWrapper: {
    display: 'flex',
    width: '100%',
    transition: 'transform 0.3s',
    '&.megaMenuWrapper': {
      '&.hideL2List': {
        '& .l2List': {
          transform: `translateX(${theme.mixins.translateX(-100)}%)`,
          transition: 'transform 0.3s',
        },
      },
      '&:not(.hideL2List)': {
        '& .l3List': {
          transform: `translateX(${theme.mixins.translateX(100)}%)`,
          transition: 'transform 0.3s',
        },
      },
    },
  },
  desktopMegaMenu: {
    padding: `${pxToRem(56)} ${theme.spacing(4)} ${theme.spacing(4)} ${pxToRem(48)}`,
    flex: '0 0 100%',
    maxWidth: '100%',
    '& .stickyPart': {
      position: 'sticky',
      top: '0',
      backgroundColor: theme.mixins.White(),
      zIndex: '11',
      '& a': {
        display: 'block',
      },
    },
    '& .backButtonDesktop': {
      marginBottom: theme.spacing(4),

      '& .MuiButton-label': {
        color: theme.mixins.Black(),
      },
      '& .MuiButton-startIcon': {
        padding: '0',
        width: 'auto',
        transform: 'rotate(180deg)',
      },
      '& > span:hover': {
        '& .MuiButton-label': {
          color: theme.mixins.Black(),
        },
        '& .MuiButton-startIcon': {
          color: theme.mixins.Black(),
          backgroundColor: 'transparent',
        },
      },
    },
    '& .MuiAvatar-root': {
      width: theme.spacing(6),
      height: theme.spacing(6),
      ...theme.mixins.marginLeft(pxToRem(20)),
      ...theme.mixins.marginRight(pxToRem(20)),
    },
    '& ul': {
      padding: 0,
      '& > li': {
        padding: 0,
        minHeight: 0,
        cursor: 'default',
        overflow: 'inherit',
        whiteSpace: 'normal',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '& > a': {
          display: 'inline-flex',
          alignItems: 'center',
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          position: 'relative',
          width: '100%',
          '&::after': {
            display: 'block',
            content: '""',
            backgroundColor: theme.mixins.AbuDhabiOrange(),
            position: 'absolute',
            ...theme.mixins.left(0),
            top: 0,
            width: pxToRem(4),
            height: '100%',
            transform: 'scaleY(0)',
            transition: 'transform 0.3s ease-in-out',
          },
          '&:hover': {
            color: theme.mixins.AbuDhabiOrange(),
            '&::after': {
              transform: 'scaleY(1)',
            },
            '& > span': {
              fontWeight: 'bold',
              pointerEvents: 'none',
            },
          },
          '&:focus': {
            outline: 'none',
            border: 0,
          },
          ...theme.mixins.paddingLeft(pxToRem(30)),
          '& .MuiSvgIcon-root': {
            ...theme.mixins.marginLeft(theme.spacing(1)),
            transform: theme.mixins.rotate0(),
            padding: theme.spacing(0.25),
            pointerEvents: 'none',
          },
        },
      },
    },
  },
  '@keyframes arrowMove': {
    '0%': { transform: 'translateX(0)' },
    '50%': { transform: `translateX(${theme.mixins.translateX(130)}%)` },
    '51%': { transform: `translateX(${theme.mixins.translateX(-130)}%)` },
    '100%': { transform: 'translateX(0)' },
  },
  '@global': {
    '.offScreen': {
      left: '-100%',
      opacity: '0',
    },
    '.onScreen': {
      left: '0',
      visibility: 'visible',
    },
    '.MuiAppBar-root.megaNavOpened.is-transparent .mega-navigation-list .nav-text.unhovered': {
      opacity: 1,
    },
    '.MuiAppBar-root.megaNavOpened .mega-navigation-list a:not(.active) span:not(:hover)': {
      [theme.breakpoints.up('lg')]: {
        color: theme.mixins.Black(),
      },
    },
    '.MuiAppBar-root.megaNavOpened': {
      zIndex: '1310',
      position: 'fixed',
      transform: 'translateY(0) !important',
      visibility: 'visible !important',
      color: theme.palette.secondary.contrastText,
      background: theme.palette.secondary.main,
      transition: 'none !important',
      '& .MuiGrid-root': {
        background: 'transparent',
      },
      '&.hidden': {
        transform: 'translateY(-14rem) !important',
      },
      '& .business-link': {
        borderColor: theme.mixins.Black(200),
      },
      '& .language-selector-wrapper': {
        '& svg path': {
          fill: theme.mixins.Black(),
        },
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: theme.spacing(16),
        boxShadow: `0px 2px 4px 0px ${theme.mixins.Black(100)}`,
      },
    },
  },
}));

export default useStyles;
